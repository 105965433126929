import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { omit } from 'dpl/shared/utils';
import { subscribeToChildListChanges } from 'dpl/util/dom';

// HACK: Allow for events to bubble up for Croppie instances
// because Croppie binds everything to window :'(
const CLASS_BLACKLIST = [
  'cr-boundary',
  'cr-overlay',
  'cr-slider',
  'cr-slider-wrap',
  'cr-viewport',
  'croppie-container',
  'SimpleSlider__thumb'
];

export const OPT_OUT_CLASS = 'dpl-fixed-scroll-wrapper-opt-out';

/**
 * The purpose for this component is only to allow its content to be
 * scrollable while making any elements underneath it stay put.
 *
 * This is to mitigate an issue with iTouch devices which, despite the
 * elements scrolling capabilities, delegate scrolling to the body element
 * when it reaches its top or bottom (ie `overscroll: contain` is not in their
 * lexicon.)
 *
 * Implementation taken from: https://stackoverflow.com/a/41601290/3220101
 *
 */

export default class FixedScrollWrapper extends Component {
  static propTypes = {
    className: PropTypes.string,
    setRef: PropTypes.func,
    axis: PropTypes.oneOf(['X', 'Y'])
  };

  static defaultProps = {
    className: null,
    setRef: () => {},
    axis: 'Y'
  };

  _touchTargetStartPosition = {
    clientX: null,
    clientY: null
  };

  scrollingEl = createRef();

  optedOutElements = [];

  isTotallyScrolledHorizontally(touchTargetX) {
    const newTouchTargetX =
      touchTargetX - this._touchTargetStartPosition.clientX;

    const { scrollLeft, scrollWidth, clientWidth } = this.scrollingEl.current;

    return (
      // all the way right
      (scrollWidth - scrollLeft <= clientWidth && newTouchTargetX < 0) ||
      // all the way left
      (scrollLeft === 0 && newTouchTargetX > 0)
    );
  }

  isTotallyScrolledVertically(touchTargetY) {
    const newTouchTargetY =
      touchTargetY - this._touchTargetStartPosition.clientY;

    const { scrollTop, scrollHeight, clientHeight } = this.scrollingEl.current;

    return (
      // all the way bottom
      (scrollHeight - scrollTop <= clientHeight && newTouchTargetY < 0) ||
      // all the way top
      (scrollTop === 0 && newTouchTargetY > 0)
    );
  }

  disableRubberBandIfNeeded(e) {
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
    const {
      targetTouches: [event]
    } = e;
    const isTotallyScrolled =
      this.props.axis === 'X'
        ? this.isTotallyScrolledHorizontally(event.clientX)
        : this.isTotallyScrolledVertically(event.clientY);

    if (
      isTotallyScrolled &&
      event.target.nodeName !== 'TEXTAREA' &&
      this.optedOutElements.every(el => !el.contains(event.target))
    ) {
      e.preventDefault();
    }
  }

  handleTouchMove = e => {
    const targetClasses = [...e.target.classList];
    if (CLASS_BLACKLIST.filter(c => targetClasses.includes(c)).length > 0) {
      return;
    }

    if (e.targetTouches.length === 1) {
      // Stopping propagation here so we don't block elements from
      // scrolling when a parent element is already blocking. for instance,
      // MobileMenu blocks to only scroll w/in itself, but it may have children
      // who have overflow-scroll as well.
      //
      // TODO: The issue w this is that there may be other listeners listening
      // to touchmove which this would break
      e.stopPropagation();

      if (e.cancelable) {
        this.disableRubberBandIfNeeded(e);
      }
    }
  };

  handleTouchStart = e => {
    if (e.targetTouches.length === 1) {
      const { clientX, clientY } = e.targetTouches[0];
      this._touchTargetStartPosition = { clientX, clientY };
    }
  };

  componentDidMount() {
    // TODO: this is just so tests can pass; probs better to fix w createMockNode
    if (this.scrollingEl.current && this.scrollingEl.current.addEventListener) {
      this.scrollingEl.current.addEventListener(
        'touchstart',
        this.handleTouchStart
      );
      this.scrollingEl.current.addEventListener(
        'touchmove',
        this.handleTouchMove
      );

      this.props.setRef(this.scrollingEl.current);

      this.optedOutElements = [
        ...document.getElementsByClassName(OPT_OUT_CLASS)
      ];

      this.unsubscribeChildChanges = subscribeToChildListChanges(
        this.scrollingEl.current,
        mutation => {
          mutation.addedNodes.forEach(node => {
            if (node instanceof window.HTMLElement) {
              this.optedOutElements = [
                ...this.optedOutElements,
                ...node.getElementsByClassName(OPT_OUT_CLASS)
              ];
            }
          });
        }
      );
    }
  }

  componentWillUnmount() {
    // TODO: this is just so tests can pass; probs better to fix w createMockNode
    if (
      this.scrollingEl.current &&
      this.scrollingEl.current.removeEventListener
    ) {
      this.scrollingEl.current.removeEventListener(
        'touchstart',
        this.handleTouchStart
      );
      this.scrollingEl.current.removeEventListener(
        'touchmove',
        this.handleTouchMove
      );
      this.unsubscribeChildChanges();
    }
  }

  render() {
    const { className, axis, ...props } = this.props;

    return (
      <div
        {...omit(props, ['setRef'])}
        ref={this.scrollingEl}
        className={classnames(className, {
          'overflow-y-auto': axis === 'Y',
          'overflow-x-auto': axis === 'X'
        })}
      />
    );
  }
}
