import { compose } from 'redux';

export function getScrollTop() {
  return window.scrollY || window.pageYOffset;
}

export function getTopOffsetFromViewport(el) {
  return el.getBoundingClientRect().top + getScrollTop();
}

export function fitInViewport(el) {
  el.style.height = `${window.innerHeight - getTopOffsetFromViewport(el)}px`;
}

export function setDocumentTitle(content) {
  const prevTitle = document.title;

  document.title = content;

  return function resetDocumentTitle() {
    document.title = prevTitle;
  };
}

export function subscribeToChildListChanges(container, fn) {
  if (!window.MutationObserver) {
    return () => {};
  }

  const observer = new window.MutationObserver(mutationsList => {
    [...mutationsList].forEach(fn);
  });

  observer.observe(container, {
    childList: true,
    subtree: true,
    attributes: false,
    characterData: false
  });

  return function unsubscribe() {
    observer.disconnect();
  };
}

export function bindAll(container, selector, eventName, eventHandler) {
  let els = container.querySelectorAll(selector);

  els.forEach(el => {
    el.addEventListener(eventName, eventHandler);
  });

  return function unbindAll() {
    els.forEach(el => {
      el.removeEventListener(eventName, eventHandler);
    });

    els = [];
  };
}

export function liveBind(container, selector, eventName, eventHandler) {
  let unbindAll = bindAll(container, selector, eventName, eventHandler);

  const unsubscribe = subscribeToChildListChanges(container, mutation => {
    mutation.addedNodes.forEach(insertedEl => {
      const _container = insertedEl.parentElement || insertedEl;
      if (_container instanceof window.HTMLElement) {
        const _unbindAll = bindAll(
          _container,
          selector,
          eventName,
          eventHandler
        );
        unbindAll = compose(_unbindAll, unbindAll);
      }
    });
  });

  return function unbind() {
    unbindAll();
    unsubscribe();
  };
}
