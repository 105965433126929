/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactModal from 'react-modal';
import invariant from 'invariant';

import FixedScrollWrapper from 'dpl/components/FixedScrollWrapper';
import Icon from 'dpl/common/components/Icon';
import { isNativeAppUA } from 'dpl/shared/utils';

if (!CONFIG.isTest) ReactModal.setAppElement('body');

export const REACT_MODAL_MOUNT_SELECTOR = '[data-react-mount-modal]';

function getModalMountElement() {
  const result = document.querySelector(REACT_MODAL_MOUNT_SELECTOR);

  invariant(
    result,
    `
  While attempting to configure an overlay modal,
  we could not find a div with the ${REACT_MODAL_MOUNT_SELECTOR} present.
  Please ensure a div is present in the erb file with that attribute and try this again.
  `
  );
  return result;
}

export default class OverlayModal extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    closeButtonLabel: PropTypes.string,
    className: PropTypes.string,
    hideCloseButton: PropTypes.bool,
    disableClose: PropTypes.bool,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    zIndexClass: PropTypes.string,
    dataTestId: PropTypes.string,
    wrapper: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
  };

  static defaultProps = {
    className: '',
    closeButtonLabel: null,
    hideCloseButton: isNativeAppUA(),
    onClose: () => ({}),
    disableClose: false,
    onOpen: null,
    zIndexClass: 'z-max',
    dataTestId: 'overlay-modal',
    wrapper: FixedScrollWrapper
  };

  state = {
    isMounted: false,
    isVisible: false
  };

  componentDidMount() {
    window.setTimeout(() => {
      this.setState({ isMounted: true });
    });
  }

  handleClose = e => {
    e.preventDefault();

    if (this.props.disableClose) {
      return;
    }

    this.setState({
      isVisible: false,
      isMounted: false
    });
    this.props.onClose();
  };

  handleOpen = () => {
    this.setState({ isVisible: true });
    if (this.props.onOpen) {
      this.props.onOpen();
    }
  };

  render() {
    const { isMounted, isVisible } = this.state;
    const {
      children,
      className,
      closeButtonLabel,
      hideCloseButton,
      zIndexClass,
      dataTestId,
      wrapper: Wrapper
    } = this.props;

    const classes = classnames(
      className,
      zIndexClass,
      'OverlayModal fixed absolute--fill bg-blur-5',
      {
        'o-100': isVisible,
        'o-0': !isVisible
      }
    );

    return (
      <ReactModal
        isOpen={isMounted}
        className="h-100"
        overlayClassName={classes}
        onAfterOpen={this.handleOpen}
        onRequestClose={this.handleClose}
        onAfterClose={this.handleAfterClose}
        shouldCloseOnEsc
        parentSelector={getModalMountElement}
        data={{ 'test-id': dataTestId }}
      >
        <Wrapper className="w-100 h-100 relative center">
          {!hideCloseButton && (
            <a
              className="OverlayModal__closeButton fr pv4 ph6 relative z-max mid-gray"
              onClick={this.handleClose}
              role="button"
              title="modal_close"
            >
              <div className="flex">
                {closeButtonLabel && (
                  <span className="d-none d-md-block nowrap mr3">
                    {closeButtonLabel}
                  </span>
                )}
                <Icon name="close" />
              </div>
            </a>
          )}
          {children}
        </Wrapper>
      </ReactModal>
    );
  }
}
